import React from "react";
import { InteractiveMap } from "../../components/Map/InteractiveMap";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const LocationMapSection = ({ geolocations, data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <Wrapper>
      <LeftSide>
        <InteractiveMap data={items} />
      </LeftSide>
      <RightSide>
        <Div>
          <Title
            capital="uppercase"
            color={color.primary.blue}
            tag="h3"
            weight={typography.weight.regular1}
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </Div>
        <TextExtend
          tag="p"
          font="Oxygen"
          lineHeight={26}
          color={color.netraul.black100}
        >
          <RichText
            render={primary?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </TextExtend>
      </RightSide>
    </Wrapper>
  );
};
const TextExtend = styled(Text)`
  width: 455px;
  @media (max-width: 1060px) and (min-width: 651px) {
    width: 100%;
  } 
  @media (max-width: 650px) {
    width: 100%;
    div {
      margin-bottom: 20px;
    }
  }
`;
const Title = styled(Text)`
  width: 455px;
  letter-spacing: -0.015em;
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 100%;
    width: 100%;
    font-size: 62px !important;
      line-height: 62px !important;
    span {
      font-size: 62px !important;
      line-height: 62px !important;
      margin-bottom: 20px;
    }
  } 
  @media (max-width: 759px) {
    width: 100%;
    font-size: 56px !important;
      line-height: 56px !important;
    span {
      font-size: 56px !important;
      line-height: 56px !important;
      margin-bottom: 20px;
    }
  }
`;
const Div = styled.div``
const RightSide = styled.div`
  display: flex;
  gap: 32px;
  width: 44%;
  flex-direction: column;
  margin-left: 110px;
  @media (max-width: 1057px){
    align-items: flex-start;
  margin-left: 80px;
  }
  @media (max-width: 700px){
  width: 70%;
  }
  @media (max-width: 649px) {
    width: 100%;
    align-items: flex-start;
    text-align: start;
    margin-left: 0;
    padding-bottom: 188px;
  }
`;
const LeftSide = styled.div`
  height: 580px;
  width: 56%;
  @media (max-width: 1057px) {
    height: auto;
  }
  @media (max-width: 650px) {
    height: 200px;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  max-width: 1500px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0px 130px 0 86px;
  display: flex;
  flex-direction: row;
  background-color: white;
  @media (max-width: 1057px) {
    align-items: center;
    padding: 100px 40px;
  }
  @media (max-width: 649px) {
    flex-direction: column-reverse;
    padding: 0 20px;
    padding-top: 80px !important;
    padding-bottom: 80px;
  }
`;
LocationMapSection.propTypes = {
  geolocations: PropTypes.arrayOf(
    PropTypes.shape({
      geolocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      label: PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
      })
      ,
      link: PropTypes.shape({
        link_type: PropTypes.string,
      }),
    })
  ),
};

LocationMapSection.defaultProps = {
  geolocations: [
    {
      geolocation: {
        latitude: 16,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da Nang Hello",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.5604128,
        longitude: 77.6697583,
      },
      label: {
        type: "heading1",
        text: "An do",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: -34.7678813,
        longitude: 19.799,
      },
      label: {
        type: "heading1",
        text: "Chau phi",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 22.01648534731439,
        longitude: -79.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 42.91648534731439,
        longitude: 19.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.31648534731439,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
  ],
};
