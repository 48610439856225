import { useStaticQuery, graphql } from "gatsby"

export const useQueryInvestor = () => {
  const { allPrismicInvestor, allEventbriteEvent } = useStaticQuery(
    graphql`
      query QueryInvestor {
        allPrismicInvestor {
          nodes {
            data {
              body {
                ... on PrismicInvestorBodyBanner {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    banner {
                      url
                      alt
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body1 {
                ... on PrismicInvestorBody1Achieverment {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    name {
                      text
                      html
                      raw
                    }
                    subname {
                      text
                      html
                      raw
                    }
                    color_bubble
                    quantity {
                      text
                      html
                      raw
                    }
                  }
                }
              }
              body2 {
                ... on PrismicInvestorBody2JoinUs {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                  }
                  items {
                    reason {
                      html
                      text
                      raw
                    }
                    reason_description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body3 {
                ... on PrismicInvestorBody3Interest {
                  id
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    interest_title {
                      html
                      text
                      raw
                    }
                    interest_description {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body4 {
                ... on PrismicInvestorBody4Blog {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                  items {
                    blog {
                      document {
                        ... on PrismicBlogDetail {
                          uid
                          data {
                            image {
                              alt
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body5 {
                ... on PrismicInvestorBody5SuccessStories {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    logo {
                      url
                      alt
                    }
                    raised {
                      html
                      text
                      raw
                    }
                    currency_prefix {
                      text
                    }
                    min_investment {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body6 {
                ... on PrismicInvestorBody6GlobalMap {
                  id
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    continent
                    total_partners {
                      html
                      text
                      raw
                    }
                    total_startups {
                      html
                      text
                      raw
                    }
                    funding_amount {
                      html
                      text
                      raw
                    }
                    countries {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body7 {
                ... on PrismicInvestorBody7GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicInvestorBody7OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicInvestorBody7TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicInvestorBody7PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
            }
          }
        },
        allEventbriteEvent(limit: 3) {
          edges {
            node {
            summary
            url
            logo {
              original {
                url
              }
            }
            start {
              timezone
              local
              utc
            }
          }
        }
        totalCount
      }
      }
      `
  )

  return {
    allPrismicInvestor,
    allEventbriteEvent
  }
}