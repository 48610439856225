import React from 'react'
import styled from 'styled-components';
import { color } from '../../shared/style';
import { Banner } from './Banner';
import { Conection } from './Conection';
import { Interest } from './Interest';
import { JoinUs } from './JoinUs';
 import { SuccessStories } from './SucessStories';
import { LocationMapSection } from './LocationMapSection';
import { Events } from './Events';
import { Features } from './Features';

export const InvestorContainer = ({ data, events }) => {
    return (
      <WrapperLayout>
        <Banner
          data={data?.body[0] === undefined ? null : data?.body[0]?.primary}
        />
        <Conection
          data={data?.body1[0] === undefined ? null : data?.body1[0]?.primary}
          items={data?.body1[0] === undefined ? null : data?.body1[0]?.items}
        />
        <JoinUs data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
        <Interest data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
        <LocationMapSection
          data={data?.body6[0] === undefined ? {} : data?.body6[0]}
        />
        <Features data={data?.body4[0] === undefined ? {} : data?.body4[0]} />
        {data?.body5[0] && (
          <SuccessStories
          data={data?.body5[0] === undefined ? {} : data?.body5[0]}
        />
        )}
        <Events data={events} />
      </WrapperLayout>
    );
}

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  margin: 0 auto;
  margin-top: 90px;
  background-color: ${color.primary.blue05};
  position: relative;
  /* display: flex;
    flex-direction: column;
    align-items: center; */
`;