import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { redirectPage } from "../../hooks/redirect";
// import image from "../../images/forbes.svg";
// import icon from "../../images/kit.svg";
// import { Button } from "../../components/Button/Button";

export const Features = ({ data }) => {
  const { primary, items } = data;
  const redirectPageBlog = (url) => {
    window.location.href = `/${url}`;
  };
  if (!primary || !items) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Title
          font={typography.type.code}
          tag="h3"
          color={color.primary.blue}
          capital="uppercase"
          size={typography.size.code}
          weight={typography.weight.regular}
          lineHeight={90}
          align="center"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
        <WrapperGeneralTop>
          {items &&
            items?.map((item, i) => (
              // <BoxImage key={i}>
              <Image
                onClick={() => redirectPageBlog(item?.blog?.document?.uid)}
                key={i}
                src={item?.blog?.document?.data?.image?.url}
              />
              // </BoxImage>
            ))}
        </WrapperGeneralTop>
        <ButtonExtend 
        type="primary"
        onClick={()=>
          redirectPage({
            url: primary?.button_link?.url,
            type: primary?.button_link?.type,
            link_type: primary?.button_link?.link_type
          })
        }
        >
          {primary?.button_label?.text}
        </ButtonExtend>
      </WrapperGeneral>
    </WrapperSection>
  );
};

const ButtonExtend = styled(Button)`
  margin-top: 40px;
`

const WrapperSection = styled.div`
  background-color: ${color.primary.white};
  max-width: 1500px;
  width: 100%;
  padding: 0 130px 150px 130px;
  box-sizing: border-box;
  margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 80px; */
    @media (max-width: 1300px) and (min-width: 1001px) {
      /* padding: 80px; */
    }
    @media (max-width: 1000px) and (min-width: 651px) {
      padding: 0 40px;
    }
    @media (max-width: 650px) {
      padding: 0 20px 80px 20px;
    }
`;
const WrapperGeneral = styled.div`
  width: 100%;
  display: flex;
    flex-direction: column;
    align-items: center;
`;
const WrapperGeneralTop = styled.div`
  padding-top: 60px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 19px;
  grid-row-gap: 22px;
  /* justify-content: center; */
  @media (max-width: 1366px) {
    width: inherit;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: 1240px) {
    width: auto;
  }
  @media (max-width: 650px) {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 20px;
  }
`;

const Image = styled.img`
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 55px rgb(0 0 0 / 22%);
  }
`;

// const BoxImage = styled.div`
//   background: ${color.primary.blue05};
//   display: flex;
//   max-width: 295px;
//   max-height: 184px;
//   img {
//     width: 55%;
//     padding: 14.25% 23.055%;
//   }
//   @media (max-width: 650px) {
//   }
//   @media (max-width: 375px) {
//     width: 158px;
//     height: 94px;
//     img {
//       width: 60%;
//     }
//   }
// `;
const Title = styled(Text)`
  /* width: 22%; */
  letter-spacing: -0.015em;
  @media (max-width: 1458px) {
    /* width: 35%; */
  }
  @media (max-width: 1125px) {
    /* width: 45%; */
  }
  @media (max-width: 1024px) and (min-width: 650px) {
    width: auto;
  }
  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px !important;
    font-weight: ${typography.weight.regular1};
    line-height: 62px !important;
    width: auto;
  }
`;