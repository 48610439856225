import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Link } from '../../components/Link/Link'
// import { Button } from '../../components/Button/Button'
import Fade from "react-reveal/Fade";
import useApp from '../../contexts/context';
import { CarouselSlick } from "../../components/CarouselSlick/CarouselSlick";

const Item = ({ item }) => {
  const { isMobile } = useApp();
  const getDate = (d) => {
    let date = new Date(d);
    return date.toDateString().split(" ").slice(1).join(" ");
  };
  if (!item) return null;
  return (
    <WrapperItem>
      <Fade distance="10px" disabled={isMobile}>
        <ContainerItem>
          <WrapperInfo>
            <WrapInfoBodyItem>
              <ImageItem src={item?.node?.logo?.original?.url} />
              <Day>{getDate(item?.node?.start?.local)}</Day>
              <WrapperTitle>
                <Title
                  color={color.primary.white}
                  tag="h5"
                  capital="uppercase"
                  font="Alternate Gothic No1 D"
                  weight={typography.weight.regular}
                >
                  {item?.node?.summary}
                </Title>
              </WrapperTitle>
            </WrapInfoBodyItem>
            <GroupButtonContainer>
              <Btn href={item?.node?.url} target="_blank">
                Register
              </Btn>
            </GroupButtonContainer>
          </WrapperInfo>
        </ContainerItem>
      </Fade>
    </WrapperItem>
  );
};
const SeeMore = ({ position }) => {
  return (
    <TextExtend
      font="Oxygen"
      position={position}
      weight={typography.weight.bold}
    >
      <TextLink
        blank={true}
        to={"https://www.eventbrite.com/"}
        color={color.primary.white}
      >
        See all upcoming events
      </TextLink>
    </TextExtend>
  );
};
export const Events = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          nextArrow: null,
          prevArrow: null,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
    ],
  };
  if(!data) return null;
  return (
    <Wrapper>
      <Contain>
        <Head>
          <Text
            tag="h3"
            weight={typography.weight.regular1}
            color={color.primary.white}
            capital="uppercase"
          >
            UPCOMING EVENTS
          </Text>
          <SeeMore position="head" />
        </Head>
        <Divider />
        <WrapperContent>
          <CarouselSlick settings={settings}>
            {data?.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </CarouselSlick>
        </WrapperContent>
        <SeeMore position="foot" />
      </Contain>
    </Wrapper>
  );
};

const GroupButtonContainer = styled.div``;
const WrapInfoBodyItem = styled.div``;
const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const WrapperTitle = styled.div`
  margin-bottom: 40px;
  @media (max-width: 700px) {
  margin-bottom: 20px;
  }
`;
const Btn = styled(Link)`
  display: block;
  line-height: 26px;
  border-radius: 25px;
  background-color: ${color.secondary.yellowcream};
  color: ${color.netraul.black100};
  padding: 7px 35px;
  font-size: ${typography.size.s3}px;
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.primary};
  cursor: pointer;
  width: fit-content;
  letter-spacing: 0.035em;
  text-decoration: none;
  transition-duration: 0.5s;
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 700px) {
    position: relative;
  }
`;
const Day = styled.div`
  color: ${color.primary.blue20};
  font-weight: ${typography.weight.bold};
  line-height: 22px;
  font-size: 16px;
  font-family: ${typography.type.primary};
  letter-spacing: 0.2em;
  margin: 16px 0px;
`;
const Title = styled(Text)`
  padding: 10px 0;
  font-size: 36px;
  line-height: 36px;
  @media (max-width: 650px) {
    font-size: 30px !important;
  }
`;
const ImageItem = styled.img`
  object-fit: cover;
  margin-bottom: 8px;
  width: 100%;
  max-height: 100%;
`;
const WrapperItem = styled.div`
  height: 100%;
  .react-reveal {
    height: 100%;
  }
  margin: 0 17px;

  cursor: pointer;
  box-sizing: border-box;
  @media (max-width: 650px) {
    padding: 0;
    padding-bottom: 30px;
  }
`;
const ContainerItem = styled.div`
  height: 100%;
  @media (max-width: 700px) {
    width: 100%;
    padding-bottom: 0;
  }
`;

const Contain = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

const WrapperContent = styled.div`
  margin: 0 -17px;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 10px solid ${color.primary.blue05};
  opacity: 0.05;
  margin: 45px 0;
  @media (max-width: 650px) {
    margin: 24px 0;
  }
`;
const TextLink = styled(Link)`
  border: 1px solid ${color.secondary.emerald};
  padding: 19.5px 45px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  justify-content: center;
`;
const TextExtend = styled(Text)`
  display: flex;
  align-items: flex-end;
  display: ${(props) => (props.position === "foot" ? "none" : "flex")};
  @media (max-width: 650px) {
    margin-top: 40px;
    margin-bottom: 60px;
    display: ${(props) =>
      props.position === "foot" ? "inline-block" : "none"};
    width: 100%;
  }
`;
const Head = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    padding-top: 20px;
    align-items: flex-start;
  }
`;
const Wrapper = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${color.primary.blue};
  padding: 0 130px 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 100px;
  @media (max-width: 1000px) {
    padding: 40px;
  }
  @media (max-width: 650px) {
    padding: 20px;
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }
  .slick-dots li button:before {
    font-size: 16px;
    color: ${color.primary.white};
  }
  .slick-arrow {
    display: none !important;
  }
`;

Events.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        image: PropTypes.shape({ url: PropTypes.string, }),
        description: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        link_register: PropTypes.shape({ url: PropTypes.string, }),
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};