import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import banner from "../../images/banner-venture.png";
import { color, typography } from "../../shared/style";

export const Banner = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection banner={data?.banner?.url}>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h2"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
          size={120}
          lineHeight={120}
        >
          <RichText render={data?.title?.raw} htmlSerializer={htmlSerializer} />
        </TextExtend>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.s3}
          lineHeight={26}
        >
          <RichText
            render={data?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Description>
        {/* <GroupButton>
          <ButtonExtended
            type="primary"
          >
            {data?.button_label?.text}
          </ButtonExtended>
        </GroupButton> */}
      </Content>
    </WrapperSection>
  );
};

// const GroupButton = styled.div`
//   display: flex;
//   justify-content: center;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
//   @media (max-width: 650px) {
//     padding: 0 50px;
//   }
// `;
// const ButtonExtended = styled(Button)`
//   border-radius: 40px;
//   height: 65px;
//   white-space: nowrap;
//   margin: 7px;
//   @media (max-width: 650px) {
//     margin: 10px;
//   }
// `;
const TextExtend = styled(Text)`
  @media (max-width: 1000px) and (min-width: 651px) {
    padding: 20px 0;
    font-size: 90px !important;
    line-height: 90px;
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapperSection = styled.div`
  min-height: 700px;
  max-width: 100%;
  margin-top: -90px;
  box-sizing: border-box;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  @media (max-width: 650px) {
    min-height: 520px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  padding: 212px 337px 154px;
  @media (min-width: 1400px) {
  }
  @media (max-width: 1300px) {
  padding: 212px 0px 154px;
    width: 80%;
  }
  @media (max-width: 650px) {
  padding: 182px 0 86px;
    width: 95%;
  }
`;

const Description = styled(Text)`
  width: 60%;
  margin-top: 16px;
  span {
    font-weight: ${typography.weight.bold};
    font-size: ${typography.size.s3}px !important;
    line-height: 26px !important;
  }
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 94%;
  }
`;
