import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
// import image from "../../images/joinus-venture.png";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

export const JoinUs = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperContent>
        <BoxTitle>
          <Title
            font={typography.type.code}
            tag="h3"
            lineHeight={90}
            weight={typography.weight.regular1}
            color={color.primary.blue}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </BoxTitle>
        <ImageMobile src={primary?.image?.url}></ImageMobile>
        <WrapperItem>
          {items &&
            items.map((item, i) => {
              return (
                <Item key={i}>
                  <Description
                    font={typography.type.primary}
                    lineHeight={26}
                    weight={typography.weight.bold}
                    color={color.netraul.black100}
                  >
                    {item?.reason?.text}
                  </Description>
                  <Content
                    font={typography.type.primary}
                    lineHeight={26}
                    weight={typography.weight.regular}
                    color={color.netraul.black100}
                  >
                    {item?.reason_description?.text}
                  </Content>
                </Item>
              );
            })}
        </WrapperItem>
      </WrapperContent>
      <Image src={primary?.image?.url}></Image>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  gap: 90px;
  @media (max-width: 1160px) and (min-width: 651px) {
    gap: 40px;
  }
`;

const WrapperContent = styled.div`
  padding: 90px 0 50px 131px;
  @media (max-width: 1160px) and (min-width: 769px) {
    padding: 100px 0 50px 40px;
  }
  @media (max-width: 768px) {
    padding: 70px 0px 50px;
  }
`;
const Image = styled.img`
  object-fit: cover;
  width: 35%;
  @media (max-width: 1160px) and (min-width: 769px) {
    width: 45%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const BoxTitle = styled.div`
  width: 80%;
  @media (max-width: 1500px) {
  width: 100%;
  }
  @media (max-width: 768px) {
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 40px;
  }
`;
const Title = styled(Text)`
  span {
    white-space: normal;
    letter-spacing: -0.015em;
  }
  @media (max-width: 1160px) {
    font-size: 62px !important;
    line-height: 62px !important;
  }
  span {
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 90px !important;
    line-height: 90px !important;
    @media (max-width: 1160px) {
      font-size: 62px !important;
      line-height: 62px !important;
    }
  }
  @media (max-width: 649px) {
    line-height: 62px !important;
  }
`;
const WrapperItem = styled.div`
  margin-top: 25px;
  @media (max-width: 768px) {
    div:first-child {
      border-top: none;
      padding-top: 0px;
    }
    padding: 0 20px;
    margin-top: 40px;
  }
`;
const Item = styled.div`
  display: flex;
  padding: 50px 0;
  border-top: 1px solid rgba(35, 35, 39, 0.1);
  justify-content: space-between;
  @media (max-width: 1160px) and (min-width: 651px) {
    padding: 20px 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 30px 0 20px;
  }
`;
const Description = styled(Text)`
  width: 25%;
  @media (max-width: 1160px) and (min-width: 769px) {
    width: 30%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Content = styled(Text)`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ImageMobile = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 292px;
    width: 100vw;
    object-fit: cover;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;
