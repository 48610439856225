import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
// import red_mountain from "../../images/red-moutain.png";
import left from "../../images/arrow-left.svg";
import right from "../../images/arrow-right.svg";
import { Button } from "../../components/Button/Button";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";


export const SuccessStories = ({ data }) => {
  const [item, setItem] = useState(data?.items[0]);
  const [index, setIndex] = useState(0);
  const { items, primary } = data || {};
  const changeItem = (i) => {
    setIndex(i);
    setItem(items[i]);
  }
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperTitle>
        <BoxTitle>
          <Title
            font={typography.type.code}
            tag="h3"
            lineHeight={90}
            weight={typography.weight.regular1}
            color={color.primary.blue}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <GroupButtonMobile>
            <ButtonSlide
              onClick={() =>
                changeItem(index > 0 ? index - 1 : items.length - 1)
              }
              icon={left}
            ></ButtonSlide>
            <ButtonSlide
              onClick={() =>
                changeItem(index < items.length - 1 ? index + 1 : 0)
              }
              icon={right}
            ></ButtonSlide>
          </GroupButtonMobile>
        </BoxTitle>
        <WrapperSlide>
          <GroupButton>
            <ButtonSlide
              onClick={() =>
                changeItem(index > 0 ? index - 1 : items.length - 1)
              }
              icon={left}
            ></ButtonSlide>
            <ButtonSlide
              onClick={() =>
                changeItem(index < items.length - 1 ? index + 1 : 0)
              }
              icon={right}
            ></ButtonSlide>
          </GroupButton>
          <WrapperImage>
            <Image src={item.logo?.url}></Image>
          </WrapperImage>
        </WrapperSlide>
      </WrapperTitle>
      <WrapperContent>
        <Description
          font={typography.type.code}
          size={typography.size.l3}
          lineHeight={56}
          weight={typography.weight.regular1}
          color={color.primary.white}
          capital="uppercase"
        >
          {item?.title?.text}
        </Description>
        <Content
          font={typography.type.primary}
          size={typography.size.s3}
          lineHeight={26}
          weight={typography.weight.regular}
          color={color.primary.white}
        >
          <RichText
            render={item?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Content>
        <Statistic>
          <Item>
            <Number
              font={typography.type.code}
              tag="h4"
              lineHeight={56}
              weight={typography.weight.regular1}
              color={color.primary.white}
              capital="uppercase"
            >
              {item?.currency_prefix?.text}
              {item?.raised?.text}
            </Number>
            <Label
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.bold}
              color={color.primary.white}
            >
              Raised
            </Label>
          </Item>
          <Item>
            <Number
              font={typography.type.code}
              tag="h4"
              lineHeight={56}
              weight={typography.weight.regular1}
              color={color.primary.white}
              capital="uppercase"
            >
              {item?.min_investment?.text}
            </Number>
            <Label
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.bold}
              color={color.primary.white}
            >
              Min. Investment
            </Label>
          </Item>
        </Statistic>
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 150px 131px;
  padding-top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 32px;
  background-color: ${color.primary.white};
  @media (max-width: 1160px) {
    padding: 100px 40px;
    padding-top: 0;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 0;
  }
  @media (max-width: 769px) {
    padding: 100px 20px;
    padding-top: 0;
    max-width: 100%;
    box-sizing: border-box;
    gap: 0;
  }
`;
const BoxTitle = styled.div`
  @media (max-width: 1000px) {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
  }
`;
const WrapperTitle = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1001px) {
    width: 30%;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.primary.green40};
  padding: 76px 90px 35px;
  @media (max-width: 650px) {
    padding: 40px 20px;
  }
`;
const Title = styled(Text)`
  span {
    white-space: normal;
  }
  @media (max-width: 1000px) {
    width: 70%;
    font-size: 62px !important;
    line-height: 62px !important;
  }
`;
const WrapperSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const GroupButton = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 5px;
  @media (max-width: 1000px) {
    display: none;
  }
`

const GroupButtonMobile = styled.div`
  align-self: flex-end;
  display: none;
  gap: 5px;
  @media (max-width: 1000px) {
    display: flex;
  }
`
const ButtonSlide = styled(Button)`
    border: none;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    background-color: ${color.dark.dark05};
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    padding: 0;
    &:hover {
      border-color: ${color.primary.blue};
    }
    span {
      display: none;
    }
`
const WrapperImage = styled.div`
  background-color: ${color.dark.dark05};
  padding: 35px 100px;
  @media (max-width: 1250px) and (min-width: 1001px){
    padding: 35px 50px;
  }
  @media (max-width: 1000px) {
    padding: 20px 50px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Image = styled.img`
  max-width: initial;
  width: -webkit-fill-available;
  @media (max-width: 1000px) {
    width: initial;
    max-height: 65px;
  }
`
const Description = styled(Text)`
  margin-bottom: 32px;
  @media (max-width: 650px) {
    font-weight: 500;
    font-size: 36px !important;
    line-height: 36px;
    margin-bottom: 24px;
  }
`
const Content = styled(Text)`

`
const Statistic = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 35px;
  display: flex;
  gap: 90px;
  @media (max-width: 650px) {
    gap: 40px;
  }
`
const Item = styled.div`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
`
const Number = styled(Text)`
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`
const Label = styled(Text)`

`