import React from "react"
import "../../styles/bubbles.css"
export const Bubbles = ({data, type}) => {
    // console.log(data)
    return (
        <svg className="bubbleContainer" width="675" viewBox="0 0 675 674" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="254.5" cy="136.5" r="136.5" fill={data[0].color_bubble}/>
        <g>
            <foreignObject x="160" y="50" width={type === "ventures" ? 180 : 200} className="bubblesContent" height="200">
                <p className="bubblesTitle">{data[0].quantity?.text}</p>
                <p xmlns="http://www.w3.org/1999/xhtml">
                    {data[0]?.name?.text}
                </p>
            </foreignObject>
        </g>
        <circle opacity="0.5" cx="352" cy="354" r="32" fill={data[0].color_bubble}/>
        <circle cx="533.5" cy="267.5" r="86.5" fill={data[1].color_bubble}/>
        <g>
            <foreignObject x="465" y="210" width="135" className={type === "ventures" ? "bubblesContent2" : "bubblesContent"} height="130">
                <p xmlns="http://www.w3.org/1999/xhtml">
                    {data[1].subname?.text}
                </p>
                <p className={type === "ventures" ? "bubblesTitle2" : "bubblesTitle3"}>{data[1].quantity?.text}</p>
                <p>{data[1]?.name?.text}</p>
            </foreignObject>
        </g>
        <circle cx="147.5" cy="485.5" r="147.5" fill={data[2].color_bubble}/>
        <g>
            <foreignObject x="15" y="420" width="272" className="bubblesContent" height="200">
                <p className="bubblesTitle">{data[2].quantity?.text}</p>
                <p>{data[2]?.name?.text}</p>
            </foreignObject>
        </g>
        <circle cx="453.5" cy="500.5" r="103.5" fill={data[3].color_bubble}/>
        <g>
            <foreignObject x="390" y="430" width="135" className="bubblesContent" height="200">
                <p  className="bubblesTitle">{data[3].quantity?.text}</p>
                <p>{data[3]?.name?.text}</p>
            </foreignObject>
        </g>
        <circle opacity="0.5" cx="651.5" cy="467.5" r="23.5" fill={data[3].color_bubble}/>
        <circle opacity="0.5" cx="627.5" cy="142.5" r="16.5" fill={data[1].color_bubble}/>
        <circle opacity="0.5" cx="53.5" cy="256.5" r="16.5" fill={data[1].color_bubble}/>
        <circle opacity="0.5" cx="351.5" cy="657.5" r="16.5" fill={data[2].color_bubble}/>
        </svg>
    )
}