import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import {Bubbles} from "../../components/Bubble/Bubbles"

// const dataBubble = [
//     {
//         color: "#4C8FCC",
//         quantity: "US$ 2B+",
//         name: "Draper assets under management",
//     },
//     {
//         color: "#204370",
//         quantity: "550+",
//         name: "Invesment Partners",
//     },
//     {
//         color: "#4D5391",
//         quantity: "1,000",
//         name: "Draper Funds",
//     },
//     {
//         color: "#AD0707",
//         quantity: "30+",
//         name: "Industries represented",
//     }
// ]

export const Conection = ({ data, items }) => {
  if (!data || !items) return null;
  return (
    <WrapperSection>
      <Image>
        <Bubbles data={items} />
      </Image>
      <WrapperContent>
        <Title
          tag="h3"
          font={typography.type.code}
          weight={typography.weight.regular1}
          lineHeight={90}
          capital="uppercase"
          color={color.primary.blue}
        >
          <RichText render={data?.title?.raw} htmlSerializer={htmlSerializer} />
        </Title>
        <ImageMobile>
          <Bubbles data={items} />
        </ImageMobile>
        <Content
          font={typography.type.primary}
          weight={typography.weight.regular}
          color={color.netraul.black100}
          lineHeight={26}
        >
          <RichText
            render={data?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Content>
        {/* <Description
          font={typography.type.primary}
          weight={typography.weight.regular}
          color={color.netraul.black100}
          size={20}
          lineHeight={30}
        >
          No matter your size or business stage, we will connect you with mentors and funding to accelerate your vision.
        </Description> */}
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  padding: 120px 90px 130px 57px;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  gap: 0 125px;
  background-color: ${color.primary.white};
  @media (max-width: 1200px) and (min-width: 1001px) {
    padding: 100px 40px 140px 40px;
    gap: 0 70px;
  }
  @media (max-width: 1000px) and (min-width: 650px) {
    gap: 0 70px;
    padding: 100px 20px 140px 20px;
  }
  @media (max-width: 650px) {
    padding: 70px 20px;
    gap: 0;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
    gap: 7px;
  }
`
const Title = styled(Text)`
  padding-top: 10px;
  span {
    white-space: normal;
    letter-spacing: -0.015em;
  }
  @media (max-width: 1000px) and (min-width: 651px) {
    line-height: 62px;
  } 
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`
const Content = styled(Text)`
  width: 85%;
  @media (max-width: 650px) {
    width: 100%;
  }
`
// const Description = styled(Text)`
//   width: 79%;
//   @media (max-width: 650px) {
//     width: 100%;
//   }
// `
const Image = styled.div`
  width: 50%;
  height: fit-content;
  @media (max-width: 650px) {
    display: none;
  }
`
const ImageMobile = styled.div`
  padding-bottom: 50px;
  display: none;
  @media (max-width: 650px) {
    display: block;
  padding-bottom: 8px;
  }
`
