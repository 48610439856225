import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
// import people from "../../images/people.svg";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { redirectPage } from "../../hooks/redirect";

export const Interest = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        lineHeight={90}
        weight={typography.weight.regular1}
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Title>
      <WrapperContent>
        {items &&
          items?.map((item, i) => (
            <WrapperItem key={i}>
              <Div>
                <WrapperTitle
                  color={
                    i === 0 ? color.primary.green40 : color.secondary.danube
                  }
                >
                  <TitleBox
                    tag="h4"
                    font={typography.type.code}
                    lineHeight={56}
                    weight={typography.weight.regular1}
                    color={color.primary.white}
                    capital="uppercase"
                    align="center"
                  >
                    {item.interest_title.text}
                  </TitleBox>
                </WrapperTitle>
                <WrapperContentItem>
                  <Text
                    tag="h4"
                    font={typography.type.primary}
                    lineHeight={28}
                    size={typography.size.s4}
                    weight={typography.weight.regular1}
                    color={color.netraul.black100}
                    align="center"
                  >
                    {item.interest_description.text}
                  </Text>
                  <ButtonExpand
                    onClick={() => {
                      redirectPage({
                        url: item?.button_link?.url,
                        type: item?.button_link?.type,
                        link_type: item?.button_link?.link_type,
                      });
                    }}
                    type={i === 0 && "secondary"}
                    color={i === 1 && color.secondary.danube}
                  >
                    {item.button_label.text}
                  </ButtonExpand>
                </WrapperContentItem>
              </Div>
            </WrapperItem>
          ))}
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  background-color: ${color.primary.white};
  max-width: 1500px;
  padding: 150px 130px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  @media (max-width: 1300px) and (min-width: 1001px) {
    padding: 80px;
  }
  @media (max-width: 1000px) and (min-width: 651px) {
    padding: 70px 40px 100px 40px;
    gap: 40px;
  }
  @media (max-width: 650px) {
    padding: 70px 20px 80px 20px;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  text-align: center;
  font-weight: 500;
    @media (max-width: 1150px) {
      font-size: 90px !important;
      line-height: 90px;
    }
    @media (max-width: 650px) {
      line-height: 62px !important;
      font-size: 62px !important;
    }
  span {
    letter-spacing: -0.015em;
    text-align: center;
    font-weight: 500;
    @media (max-width: 1150px){
      font-size: 90px !important;
      line-height: 90px;
    }
    @media (max-width: 650px) {
      line-height: 62px !important;
      font-size: 62px !important;
    }
  }
`;
const TitleBox = styled(Text)`
  letter-spacing: -0.015em;
  text-align: center;
  font-weight: 500;
  @media (max-width: 1150px) {
      font-size: 56px !important;
      line-height: 56px;
    }
    @media (max-width: 650px) {
      line-height: 36px !important;
      font-size: 30px !important;
    }
  span {
    letter-spacing: -0.015em;
    text-align: center;
    font-weight: 500;
    @media (max-width: 1150px) {
      font-size: 56px !important;
      line-height: 56px;
    }
    @media (max-width: 650px) {
      line-height: 36px !important;
      font-size: 30px !important;
    }
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  width: 100%;
  @media (max-width: 650px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const WrapperItem = styled.div`
  width: 50%;
  padding: 0 52px 0 52px;
  background: linear-gradient(to bottom, #fff 250px, #f4f6f8 250px);
  @media (max-width: 650px) {
    width: auto;
    padding: 0 20px;
    background: linear-gradient(to bottom, #fff 135px, #f4f6f8 135px);
  }
`;
const Div = styled.div`
  height: 100%;
`;

const WrapperContentItem = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-family: ${typography.type.primary};
  text-align: center;
  height: calc(100% - 250px);
  box-sizing: border-box;
  justify-content: space-between;
  ${(props) =>
    props.morePadding
      ? `padding: 55px 67px 59px 67px;`
      : `padding: 55px 0px 59px;`}
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 40px 0 45px 0;
  }
  @media (max-width: 650px) {
    ${(props) =>
      props.morePadding
        ? `padding: 40px 18px 45px 18px;`
        : `padding: 40px 0 45px 0;`}
  }
  display: flex;
  flex-direction: column;
  background-color: ${color.primary.blue05};
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color && props.color};
  height: 250px;
  box-sizing: border-box;
  padding: ${(props) => (props.right ? `54px 43px` : `54px 65px`)};
  span {
    @media (max-width: 1000px) and (min-width: 650px) {
      font-size: 40px !important;
      line-height: 40px;
    }
  }
  @media (max-width: 1125px) {
    padding: 20px;
  }
  @media (max-width: 650px) {
    padding: ${(props) => (props.right ? `32px 54px` : `32px`)};
    height: 136px;
  }
`;
// const Item = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 16px;
// `;
// const ItemText = styled(Text)``;
// const Icon = styled.img``;
const ButtonExpand = styled(Button)`
  span {
    font-size: ${typography.size.s3}px;
    line-height: 26px;
    font-weight: 700;
  }
  background: ${color.primary.green40};
  border: 1px solid ${color.primary.green40};
  &:hover {
    color: ${color.primary.green40};
    background-color: ${color.primary.white};
  }
  ${(props) =>
    props.color &&
    `
    background-color: ${props.color};
    border: 1px solid ${props.color};
    &:hover {
      color: ${props.color};
      background-color: white;
    }
  `}
  margin-top: 40px;
  width: fit-content;
  border-radius: 40px;
  padding: 0 45px;
  height: 65px;
  color: white;
  align-self: center;
  @media (max-width: 1000px) {
    width: auto;
    padding: 19.5px 45px;
  }
`;